import React from 'react';
import BaseWidget from './BaseWidget';

const CERTIF_ORDER = {
  plongeur: 0,
  pl_confirmed: 1,
  instructeur: 2,
  instructeur_confirmed: 3,
};

const certifOrder = (a, b) => {
  if (CERTIF_ORDER[a.level] < CERTIF_ORDER[b.level]) {
    return 1;
  }
  if (CERTIF_ORDER[a.level] > CERTIF_ORDER[b.level]) {
    return -1;
  }
  return 0;
};
const nitroxCertificateWidget = (props) => {
  const { value } = props;
  const certifList = value?.items?.sort(certifOrder);
  return certifList ? (
    <BaseWidget
      className="nitrox-certif"
      titleFn={(item) => item.level}
      items={certifList}
      firstHighlight
    />
  ) : (
    <></>
  );
};

export default nitroxCertificateWidget;
