import { READ_USER_DATA, UPDATE_USER_DATA } from '~/constants/ActionTypes';

const initialState = {
  read: {
    loaded: false,
    loading: false,
    error: null,
  },
  data: null,
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * footer reducer.
 * @function footer
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function userData(state = initialState, action = {}) {
  let { result } = action;
  switch (action.type) {
    case `${UPDATE_USER_DATA}_PENDING`:
    case `${READ_USER_DATA}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${READ_USER_DATA}_SUCCESS`:
      return {
        ...state,
        data: {
          ...result,
        },
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${READ_USER_DATA}_FAIL`:
    case `${UPDATE_USER_DATA}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case `${UPDATE_USER_DATA}_SUCCESS`:
      return {
        ...state,
        registration: result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    default:
      return state;
  }
}
