import React from 'react';
import BaseWidget from './BaseWidget';

const CERTIF_ORDER = {
  NB: 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  AM: 5,
  MC: 6,
  MF: 7,
  MN: 8,
};

const certifOrder = (a, b) => {
  if (CERTIF_ORDER[a.level] < CERTIF_ORDER[b.level]) {
    return 1;
  }
  if (CERTIF_ORDER[a.level] > CERTIF_ORDER[b.level]) {
    return -1;
  }
  return 0;
};

const divingCertificateWidget = (props) => {
  const { value } = props;
  const certifList = value?.items?.sort(certifOrder);
  return certifList ? (
    <BaseWidget
      className="diving-certif"
      titleFn={(item) => item.level}
      items={certifList}
      firstHighlight
    />
  ) : (
    <></>
  );
};

export default divingCertificateWidget;
