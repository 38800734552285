import { POST_CSV_DATA, CHECK_CSV_DATA } from '~/constants/ActionTypes';

export function csvSendData(data, type) {
  return {
    type: POST_CSV_DATA,
    request: {
      op: 'post',
      path: `/@csv`,
      data: {
        ...data,
        type,
      },
    },
  };
}
