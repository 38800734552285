import React from 'react';
import { Popup } from 'semantic-ui-react';

const DivingLevel = ({ level }) => {
  const def = {
    NB: 'Non Breveté',
    P1: 'Plongeur 1 étoile',
    P2: 'Plongeur 2 étoiles',
    P3: 'Plongeur 3 étoiles',
    P4: 'Plongeur 4 étoiles',
    AM: 'Assistant Moniteur',
    MC: 'Moniteur Club',
    MN: 'Moniteur National',
    MF: 'Moniteur Fédéral',
  };

  if (!level) {
    return <Popup trigger={<span>NB</span>} content={def['NB']} />;
  }
  const num = Number(level);
  if (Number.isInteger(num)) {
    level = `P${level}`;
  }

  return <Popup trigger={<span>{level}</span>} content={def[level]} />;
};

export default DivingLevel;
