const PriceSchema = (props) => {
  return {
    title: 'Price Item',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'description', 'price'],
      },
    ],
    properties: {
      title: { title: 'Price item title' },
      description: { title: 'Price item description' },
      price: { title: 'Price item price (€)', type: 'number' },
    },
    required: [],
  };
};

export const Schema = (props) => {
  return {
    title: 'Price List',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'description', 'align', 'items'],
      },
    ],

    properties: {
      title: {
        title: 'Table title',
      },
      description: {
        title: 'Table description',
      },
      items: {
        title: 'Table Items',
        widget: 'object_list',
        schema: PriceSchema,
      },
      align: {
        title: 'Align',
        widget: 'align',
      },
    },
    required: [],
  };
};
