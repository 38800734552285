import React from 'react';
import {
  UniversalLink,
  RenderBlocks,
  FormattedRelativeDate,
  FormattedDate,
} from '@plone/volto/components';
import { flattenToAppURL, Helmet } from '@plone/volto/helpers';
import { Segment, Header, List, Container, Message } from 'semantic-ui-react';
import { CarouselImage } from '..';

const EventView = (props) => {
  const { content } = props;
  return (
    <Container>
      <Helmet title={content.title} />
      {content.past_event && (
        <Message warning>
          <Message.Header>
            <div>
              Cet évenement est déja passé{' '}
              <FormattedRelativeDate date={content.start} locale="fr" />
            </div>
          </Message.Header>
        </Message>
      )}
      <RenderBlocks {...props}>
        <>
          <Segment>
            <List horizontal relaxed verticalAlign="top">
              <List.Item id="datetime">
                <List.Header>
                  {content.whole_day ? (
                    <>
                      <div>
                        <FormattedDate
                          format={{
                            day: '2-digit',
                            month: 'long',
                            year: '2-digit',
                            weekday: 'long',
                          }}
                          date={content.start}
                          locale="fr"
                        />
                      </div>
                      <div>
                        <FormattedDate
                          format={{
                            day: '2-digit',
                            month: 'long',
                            year: '2-digit',
                            weekday: 'long',
                          }}
                          date={content.end}
                          locale="fr"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <FormattedDate
                          format={{
                            day: '2-digit',
                            month: 'long',
                            year: '2-digit',
                            weekday: 'long',
                          }}
                          date={content.start}
                          locale="fr"
                        />
                      </div>
                      <div>
                        de{' '}
                        <FormattedDate
                          format={{
                            hour: 'numeric',
                            minute: 'numeric',
                          }}
                          date={content.start}
                          locale="fr"
                        />
                      </div>
                      <div>
                        à{' '}
                        <FormattedDate
                          format={{
                            hour: 'numeric',
                            minute: 'numeric',
                          }}
                          date={content.end}
                          locale="fr"
                        />
                      </div>
                    </>
                  )}
                </List.Header>
              </List.Item>
              <List.Item id="location">
                <List.Header>
                  <UniversalLink
                    href={flattenToAppURL(
                      '/locations/' + content.location.token,
                    )}
                  >
                    {content.location.title}
                  </UniversalLink>
                </List.Header>
              </List.Item>
            </List>
          </Segment>
          <Segment>
            <Header>Info pratique</Header>
            <List>
              <List.Item></List.Item>
            </List>
          </Segment>
        </>
      </RenderBlocks>
      <CarouselImage content={content} />
    </Container>
  );
};

export default EventView;
