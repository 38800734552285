import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Button, Form } from 'semantic-ui-react';

export default forwardRef((props, ref) => {
  const [filter, setFilter] = useState('all');

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filter !== 'all';
      },
      doesFilterPass(params) {
        const bool = params.data[props.colDef.field];
        return (filter === 'effective') === bool;
      },
      // this example isn't using getModel() and setModel(),
      // so safe to just leave these empty. don't do this in your code!!!
      getModel() {},

      setModel() {},
    };
  });

  const onFilterChange = (event, data) => {
    setFilter(data.value);
  };

  useEffect(() => {
    props.filterChangedCallback();
  }, [filter]);

  return (
    <div>
      <Button.Group vertical>
        <Button value="all" active={filter === 'all'} onClick={onFilterChange}>
          All
        </Button>
        <Button
          value="effective"
          active={filter === 'effective'}
          onClick={onFilterChange}
        >
          Effective
        </Button>
        <Button
          value="aderant"
          active={filter === 'aderant'}
          onClick={onFilterChange}
        >
          Adérant
        </Button>
      </Button.Group>
    </div>
  );
});
