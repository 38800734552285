/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import userData from './userData/userData';
import adminPage from './adminPage/adminPage';
import userMember from './userMember/userMember';
import csv from './csv/csv';
/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  // Add your reducers here
  userData,
  adminPage,
  userMember,
  csv,
};

export default reducers;
