import React from 'react';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const nitroxCertificateWidget = (props) => {
  const { value } = props;

  function onChange(id, value) {
    props.onChange(id, { items: value });
  }

  function schema(props) {
    return {
      title: 'Nitrox Certificate',
      addMessage: 'Add a certificate',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['level', 'date'],
        },
      ],

      properties: {
        level: {
          title: 'Certificate Level',
          choices: [
            ['plongeur', 'Plongeur'],
            ['pl_confirmed', 'Plongeur Confirmé'],
            ['instructeur', 'Instructeur'],
            ['instructeur_confirmed', 'Instructeur Confirmé'],
          ],
        },
        date: {
          title: 'Date',
          widget: 'date',
        },
      },
      required: ['level'],
    };
  }

  return (
    <ObjectListWidget
      {...props}
      value={value?.items}
      schema={schema}
      onChange={onChange}
    />
  );
};

export default nitroxCertificateWidget;
