/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';

import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);
  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      color="grey"
      textAlign="center"
      id="footer"
    >
      <Container>
        <Segment basic inverted color="grey" className="discreet">
          <FormattedMessage
            id="CPSM 1966-{current_year}"
            defaultMessage="CPSM 1966-{current_year}"
            values={{
              current_year: new Date().getFullYear(),
            }}
          />
        </Segment>
        <List horizontal inverted>
          {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
          <div role="listitem" className="item">
            <UniversalLink
              className="item"
              href={settings.isMultilingual ? `/${lang}/sitemap` : '/sitemap'}
            >
              <FormattedMessage id="Site Map" defaultMessage="Site Map" />
            </UniversalLink>
          </div>
          <div role="listitem" className="item">
            <UniversalLink
              className="item"
              href={
                settings.isMultilingual
                  ? `/${lang}/contact-form`
                  : '/contact-form'
              }
            >
              <FormattedMessage id="Contact" defaultMessage="Contact" />
            </UniversalLink>
          </div>
        </List>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
