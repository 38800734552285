import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import { validityCheck } from '@root/helpers';

export default forwardRef((props, ref) => {
  const [filter, setFilter] = useState({
    valid: false,
    unvalid: false,
    'almost-unvalid': false,
  });

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filter.valid || filter.unvalid || filter['almost-unvalid'];
      },
      doesFilterPass(params) {
        const validity = params.data[props.colDef.field];
        const check = validityCheck(validity);
        return (
          (filter.valid && check === 'valid') ||
          (filter.unvalid && check === 'unvalid') ||
          (filter['almost-unvalid'] && check === 'almost-unvalid')
        );
      },
      // this example isn't using getModel() and setModel(),
      // so safe to just leave these empty. don't do this in your code!!!
      getModel() {},

      setModel() {},
    };
  });

  const onFilterChange = (event, data) => {
    setFilter((prev) => {
      return { ...prev, [data.value]: data.checked };
    });
  };

  useEffect(() => {
    props.filterChangedCallback();
  }, [filter]);

  return (
    <div>
      <div className="filter-title">Validity Filter</div>
      <Form>
        <Form.Field>
          <Checkbox label="Valid" value="valid" onChange={onFilterChange} />
        </Form.Field>
        <Form.Field>
          <Checkbox label="Unvalid" value="unvalid" onChange={onFilterChange} />
        </Form.Field>
        <Form.Field>
          <Checkbox
            label="Almost Unvalid"
            value="almost-unvalid"
            onChange={onFilterChange}
          />
        </Form.Field>
      </Form>
    </div>
  );
});
