import { READ_ADMIN_PAGE, UPDATE_MEMBERS } from '@root/constants/ActionTypes';

export function readAdminPagePermission() {
  return {
    type: READ_ADMIN_PAGE,
    request: {
      op: 'get',
      path: `/admin-club-permission`,
    },
  };
}

export function updateMembers(data) {
  return {
    type: UPDATE_MEMBERS,
    request: {
      op: 'patch',
      path: `/@members`,
      data,
    },
  };
}
