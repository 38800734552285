import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNavigation,
  listActions,
  getQueryStringResults,
} from '@plone/volto/actions';
import {
  Toolbar,
  FormattedRelativeDate,
  FormattedDate,
  Unauthorized,
  Forbidden,
  Toast,
} from '@plone/volto/components';
import { Portal } from 'react-portal';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { AgGridReact } from 'ag-grid-react';
import { readAdminPagePermission } from '@root/actions';
import {
  CreateNewUserForm,
  validityFilter,
  certifFilter,
  boolFilter,
  CheckCSV,
} from '@root/components';
import { validityCheck } from '@root/helpers';
import { useClient } from '@affinitic/volto/hooks';
import { expandToBackendURL } from '@plone/volto/helpers';
import { toast } from 'react-toastify';

import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-material.css'; // Optional theme CSS

const actionColumn = (props) => {
  const permission = props.colDef.permission;
  return (
    <>
      <Button.Group>
        <Button
          as="a"
          href={props.data['@id']}
          compact
          icon
          size="mini"
          title={'View ' + props.data.first_name + ' ' + props.data.last_name}
        >
          <Icon name="eye" />
        </Button>
        {(permission === 'write' || permission === 'admin') && (
          <Button
            as="a"
            href={props.data['@id'] + '/edit'}
            compact
            icon
            size="mini"
            title={'Edit ' + props.data.first_name + ' ' + props.data.last_name}
          >
            <Icon name="edit" />
          </Button>
        )}
      </Button.Group>
    </>
  );
};

const valdityColumn = (props) => {
  const validity = props.data[props.colDef.field];

  const validityState = validityCheck(validity);

  return (
    <div className={`validity-cell ${validityState}`}>
      {validity && validityState !== 'unvalid' ? (
        <FormattedRelativeDate date={validity} locale="fr" />
      ) : (
        <strong>Not valid</strong>
      )}
    </div>
  );
};

const dateColumn = (props) => {
  const date = props.data[props.colDef.field];
  return <>{date && <FormattedDate date={date} locale="fr" />}</>;
};

const boolColumn = (props) => {
  const boolValue = props.data[props.colDef.field];
  return (
    <div className="bool-cell">
      {boolValue ? (
        <Icon name="check circle" size="large" />
      ) : (
        <Icon name="cancel" />
      )}
    </div>
  );
};

const ClubAdminView = (props) => {
  const dispatch = useDispatch();

  const permission = useSelector((state) => state.adminPage.permission);
  const token = useSelector((state) => state.userSession?.token);
  useEffect(
    () =>
      dispatch(readAdminPagePermission()).then((res) =>
        setColumnDefs((prev) => {
          const lastElem = prev.pop();
          lastElem.permission = res;
          prev.push(lastElem);
          return prev;
        }),
      ),
    [dispatch],
  );

  const [rowData, setRowData] = useState();

  const [columnDefs, setColumnDefs] = useState([
    { field: 'first_name', headerName: 'First Name' },
    { field: 'last_name', headerName: 'Last Name' },
    {
      field: 'diving_level',
      headerName: 'Diving Level',
      filter: certifFilter,
      certifMapping: {
        NB: {
          title: 'Non Breveté',
          order: 0,
        },
        '1': {
          title: 'P1',
          order: 1,
        },
        '2': {
          title: 'P2',
          order: 2,
        },
        '3': {
          title: 'P3',
          order: 3,
        },
        '4': {
          title: 'P4',
          order: 4,
        },
        AM: {
          title: 'Assitant Moniteur',
          order: 5,
        },
        MC: {
          title: 'Moniteur Club',
          order: 6,
        },
        MF: {
          title: 'Moniteur Fédéral',
          order: 7,
        },
        MN: {
          title: 'Moniteur Naitional',
          order: 8,
        },
      },
    },
    {
      field: 'dob',
      headerName: 'Date of birth',
      type: 'dateColumn',
      minWidth: 100,
      filter: 'agDateColumnFilter',
    },
    {
      field: 'last_ecg',
      headerName: 'Last ECG',
      type: 'dateColumn',
      filter: 'agDateColumnFilter',
      cellRenderer: React.memo(dateColumn),
    },
    {
      field: 'last_medical',
      headerName: 'Last Medical',
      type: 'dateColumn',
      filter: 'agDateColumnFilter',
      cellRenderer: React.memo(dateColumn),
    },
    {
      field: 'ecg_valid',
      headerName: 'ECG Validity',
      type: 'dateColumn',
      filter: validityFilter,
      cellRenderer: React.memo(valdityColumn),
    },
    {
      field: 'medical_valid',
      headerName: 'Medical Validity',
      type: 'dateColumn',
      filter: validityFilter,
      cellRenderer: React.memo(valdityColumn),
    },
    {
      field: 'registration_valid',
      headerName: 'Registration Validity',
      type: 'dateColumn',
      filter: validityFilter,
      cellRenderer: React.memo(valdityColumn),
    },
    {
      field: 'is_effective_member',
      headerName: 'Effective ?',
      filter: boolFilter,
      cellRenderer: React.memo(boolColumn),
    },
    {
      headerName: '',
      cellRenderer: React.memo(actionColumn),
      permission: permission,
      width: 100,
      resizable: false,
      sortable: false,
      filter: false,
    },
  ]);

  const [openModalNewUer, setOpenModalNewUer] = useState(false);

  const [itemsCount, setItemsCount] = useState();

  const gridRef = React.useRef();

  const [gridApi, setGridApi] = useState();

  const [checkDataOpen, setCheckDataOpen] = useState(false);

  const onGridReady = (grid) => {
    setGridApi(grid.api);
  };

  React.useEffect(() => {
    const searchDataPayload = {
      fullobjects: 0,
      metadata_fields: '_all',
      b_size: 10000000000,
      query: [
        {
          i: 'portal_type',
          o: 'plone.app.querystring.operation.selection.any',
          v: ['Member'],
        },
      ],
      sort_order: 'ascending',
    };
    dispatch(getQueryStringResults(expandToBackendURL(''), searchDataPayload))
      .then((res) => {
        setRowData(res.items);
        setItemsCount(res.items.length);
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  }, [dispatch]);

  const defaultColDef = React.useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
    }),
    [],
  );

  React.useEffect(() => {
    dispatch(getNavigation('/', 0));
    dispatch(listActions('/'));
  }, [dispatch]);

  let toolbarID = null;

  const client = useClient();

  if (client) {
    toolbarID = document.getElementById('toolbar');
  }

  const onSubmitNewUser = (value) => {
    setOpenModalNewUer(false);
  };
  const getData = (value) => {
    if (value !== 'null') {
      if ('error' in value) {
        toast.error(
          <Toast error title={value.error} content={value.fullname} />,
        );
      } else {
        setRowData((prev) => [value, ...prev]);
        toast.success(
          <Toast
            success
            title="Nouveau membre créer"
            content={`${value.first_name} ${value.last_name}`}
          />,
        );
      }
    }
  };

  const onCopyEmail = () => {
    let emails = [];
    gridApi.forEachNodeAfterFilter((rowNode, index) => {
      emails.push(
        `${rowNode.data.first_name} ${rowNode.data.last_name} <${rowNode.data.email}>`,
      );
    });
    const ccEmail = emails.join(', ');
    if (client) {
      navigator.clipboard.writeText(ccEmail);
    }
    toast.info(<Toast info title="Email copié" content={ccEmail} />);
  };

  const onFilterChanged = (event) => {
    setItemsCount(event.api.getDisplayedRowCount());
  };

  const onClickCheckData = () => {
    setCheckDataOpen(true);
  };

  const onSubmitCheckData = () => {
    setCheckDataOpen(false);
  };

  return permission ? (
    <>
      {(permission === 'read' ||
        permission === 'write' ||
        permission === 'admin') && (
        <>
          <div className="admin-view">
            <div className="toolsbar">
              {(permission === 'write' || permission === 'admin') && (
                <>
                  <Button onClick={() => setOpenModalNewUer(true)}>
                    <Icon name="plus square outline" />
                    Créer un nouveau membre du club
                  </Button>
                  <Button onClick={onClickCheckData}>
                    Verification donnée Lifras
                  </Button>
                </>
              )}
              <Button onClick={onCopyEmail} active={!gridApi}>
                <Icon />
                copier les adresse email
              </Button>
              {itemsCount ? (
                <div className="items-count">{`Nombre d'élément : ${itemsCount}`}</div>
              ) : (
                <></>
              )}
            </div>
            <div
              className="ag-theme-material"
              style={{ width: '100%', height: 500 }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={rowData} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection="multiple" // Options - allows click selection of rows
                onGridReady={onGridReady}
                onFilterChanged={onFilterChanged}
              />
            </div>
          </div>
          <Modal
            closeIcon
            open={openModalNewUer || checkDataOpen}
            onClose={() => {
              setOpenModalNewUer(false);
              setCheckDataOpen(false);
            }}
          >
            <Modal.Content scrolling>
              {openModalNewUer && (
                <CreateNewUserForm
                  onSubmit={onSubmitNewUser}
                  getData={getData}
                  onClose={() => setOpenModalNewUer(false)}
                />
              )}
              {checkDataOpen && (
                <CheckCSV
                  onSubmit={onSubmitCheckData}
                  onClose={() => setCheckDataOpen(false)}
                />
              )}
            </Modal.Content>
          </Modal>
        </>
      )}
      {!(
        permission === 'read' ||
        permission === 'write' ||
        permission === 'admin'
      ) && (
        <>
          {token ? (
            <Forbidden
              pathname={props.location.pathname}
              staticContext={props.staticContext}
            />
          ) : (
            <Unauthorized
              pathname={props.location.pathname}
              staticContext={props.staticContext}
            />
          )}
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default ClubAdminView;
