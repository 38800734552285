import { Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

const Payement = ({ onPaymentClick }) => {
  const payementAmount = useSelector(
    (state) => state.userData?.registration?.amount,
  );

  const onClick = () => {
    onPaymentClick();
  };

  return (
    <div className="finish-registration">
      <div className="payment">
        <h2>Merci de vous etes inscrit</h2>
        <h3>Bienvenu au CPSM</h3>
        <p>
          Pour completer votre inscription veuillez payer
          {
            <strong>
              {` ${Intl.NumberFormat('fr-BE', {
                style: 'currency',
                currency: 'EUR',
              }).format(payementAmount)} `}
            </strong>
          }
          sur le compte du club : <strong>BE31 0001 2832 6855</strong>
        </p>
        <p>
          Veuiller indiquer votre nom et prénom en communication du virement
        </p>
      </div>
      <div className="action">
        <Button onClick={onClick}>Fermer</Button>
      </div>
    </div>
  );
};

export default Payement;
