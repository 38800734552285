import React, { useEffect } from 'react';
import { Form, Unauthorized, Forbidden } from '@plone/volto/components';
import { useDispatch, useSelector } from 'react-redux';
import { csvSendData, readAdminPagePermission } from '@root/actions';

const ImportDataView = (props) => {
  const permission = useSelector((state) => state.adminPage.permission);
  const token = useSelector((state) => state.userSession?.token);
  useEffect(() => dispatch(readAdminPagePermission())[dispatch]);

  const schema = {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['csv'],
      },
    ],
    properties: {
      csv: {
        title: 'CSV',
        widget: 'file',
      },
    },
    required: ['csv'],
  };
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(csvSendData(data, 'import-data'));
  };

  return permission ? (
    <>
      {permission === 'admin' && (
        <Form schema={schema} onSubmit={onSubmit} resetAfterSubmit={false} />
      )}
      {permission !== 'admin' && (
        <>
          {token ? (
            <Forbidden
              pathname={props.location.pathname}
              staticContext={props.staticContext}
            />
          ) : (
            <Unauthorized
              pathname={props.location.pathname}
              staticContext={props.staticContext}
            />
          )}
        </>
      )}
    </>
  ) : (
    <></>
  );
};

export default ImportDataView;
