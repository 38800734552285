import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Label, Button } from 'semantic-ui-react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FormattedDate } from '@plone/volto/components';
import { RadioInput } from '@root/components';
import { updateMembers } from '@root/actions';

const CheckDiffView = () => {
  const listOfDiff = useSelector((state) => state.csv?.postData);

  const [checkListCpsm, setCheckListCpsm] = useState({});
  const [checkListLifras, setCheckListLifras] = useState({});

  const onChange = (value, memberName, memberId, field, side) => {
    if (side === 'empty') {
      setCheckListLifras((prev) => {
        return {
          ...prev,
          [memberName]: {
            ...prev[memberName],
            [field.lifras]: {
              change: false,
            },
          },
        };
      });
      setCheckListCpsm((prev) => {
        return {
          ...prev,
          [memberId]: {
            ...prev[memberId],
            [field.cpsm]: {
              change: false,
            },
          },
        };
      });
    }
    if (side === 'cpsm') {
      setCheckListCpsm((prev) => {
        return {
          ...prev,
          [memberId]: {
            ...prev[memberId],
            [field.cpsm]: {
              change: true,
              value: value.lifras,
            },
          },
        };
      });
      setCheckListLifras((prev) => {
        return {
          ...prev,
          [memberName]: {
            ...prev[memberName],
            [field.lifras]: {
              change: false,
            },
          },
        };
      });
    } else {
      setCheckListLifras((prev) => {
        return {
          ...prev,
          [memberName]: {
            ...prev[memberName],
            [field.lifras]: {
              change: true,
              value: value.cpsm,
            },
          },
        };
      });
      setCheckListCpsm((prev) => {
        return {
          ...prev,
          [memberId]: {
            ...prev[memberId],
            [field.cpsm]: {
              change: false,
            },
          },
        };
      });
    }
  };
  const dispatch = useDispatch();
  const onClickCPSM = () => {
    dispatch(updateMembers(checkListCpsm));
  };

  return (
    <>
      {listOfDiff &&
        listOfDiff.map((member, index) => {
          return (
            <div className="member" key={member.id}>
              <h3>{`${member.name} - ${member.febras_id}`}</h3>
              <div className="modif-date">
                <Grid columns="equal">
                  <Grid.Row>
                    <Grid.Column>Lifras</Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column>Cpsm</Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <FormattedMessage
                        id="Last Modifcation : "
                        defaultMessage="Last Modifcation : "
                      />
                      <FormattedDate date={member.modif.lifras} />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column>
                      <FormattedMessage
                        id="Last Modifcation : "
                        defaultMessage="Last Modifcation : "
                      />
                      <FormattedDate date={member.modif.cpsm} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <div className="diff-list">
                <Grid columns="equal">
                  {member.diff.map((diff, index) => {
                    return (
                      <Grid.Row key={diff.field}>
                        {['lifras', 'empty', 'cpsm'].map((side) => {
                          return side === 'empty' ? (
                            <Grid.Column width={2}>
                              <RadioInput
                                memberId={member.cpsm_id}
                                memberName={member.name}
                                field={diff.field}
                                side={side}
                                value={diff.value}
                                onChange={onChange}
                                checked
                              >
                                Unselect
                              </RadioInput>
                            </Grid.Column>
                          ) : (
                            <Grid.Column>
                              <RadioInput
                                memberId={member.cpsm_id}
                                memberName={member.name}
                                field={diff.field}
                                side={side}
                                value={diff.value}
                                onChange={onChange}
                              >
                                {diff.field[side]} :{' '}
                                {!diff.value[side] ||
                                diff.value[side] === '' ? (
                                  <Label content="Empty" color="red" />
                                ) : (
                                  diff.value[side]
                                )}
                              </RadioInput>
                            </Grid.Column>
                          );
                        })}
                      </Grid.Row>
                    );
                  })}
                </Grid>
              </div>
            </div>
          );
        })}
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column></Grid.Column>
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column>
            <Button onClick={onClickCPSM}>CPSM</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default CheckDiffView;
