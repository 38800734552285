/**
 * Add your helpers here.
 * @module helpers
 * @example
 * export { Api } from './Api/Api';
 */

export const capitilize = (props) => {
  const listOfValue = props.map((prop) => prop.value).join('');
  return listOfValue.charAt(0).toUpperCase() + listOfValue.slice(1);
};

export const daysToMiliseconds = (days) => days * 24 * 60 * 60 * 1000;

export const validityCheck = (validity) => {
  const dateValidity = new Date(validity);
  const today = new Date();
  const diff = dateValidity - today;
  let validityState = 'valid';
  if (diff < daysToMiliseconds(30)) {
    validityState = 'almost-unvalid';
  }
  if (diff < 0) {
    validityState = 'unvalid';
  }
  return validityState;
};
