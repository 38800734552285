import { READ_USER_DATA, UPDATE_USER_DATA } from '~/constants/ActionTypes';
import { nestContent } from '@plone/volto/helpers';

export function readUserData(form) {
  return {
    type: READ_USER_DATA,
    request: {
      op: 'get',
      path: `/@user-data?form=${form}`,
    },
  };
}

export function updateUserData(content, formType) {
  return {
    type: UPDATE_USER_DATA,
    request: {
      op: 'patch',
      path: '/@user-data',
      data: { ...content, formType },
    },
  };
}
