/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

export const READ_USER_DATA = 'READ_USER_DATA';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const READ_ADMIN_PAGE = 'READ_ADMIN_PAGE';

export const ADD_USER_MEMBER = 'ADD_USER_MEMBER';

export const POST_CSV_DATA = 'POST_CSV_DATA';
export const UPDATE_MEMBERS = 'UPDATE_MEMBERS';
