import React from 'react';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const securityCertificateWidget = (props) => {
  const { value } = props;

  function onChange(id, value) {
    props.onChange(id, { items: value });
  }

  function schema(props) {
    return {
      title: 'Security Certificate',
      addMessage: 'Add a certificate',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['name', 'date', 'number'],
        },
      ],

      properties: {
        name: {
          title: 'Certificate Name',
        },
        date: {
          title: 'Last Date',
          widget: 'date',
        },
        number: {
          title: 'Certificate Number',
        },
      },
      required: ['name'],
    };
  }

  return (
    <ObjectListWidget
      {...props}
      value={value?.items}
      schema={schema}
      onChange={onChange}
    />
  );
};

export default securityCertificateWidget;
