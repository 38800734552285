import React from 'react';
import { Card } from 'semantic-ui-react';
import cx from 'classnames';
const View = (props) => {
  const { data } = props;

  return (
    <>
      <Card className={cx('price-list-block block', data.align)}>
        {data.title && <Card.Header>{data.title}</Card.Header>}
        {data.description && <Card.Meta>{data.description}</Card.Meta>}
        <Card.Content>
          {data.items &&
            data.items.map(function (item, index) {
              return (
                <div className="price-item">
                  <div className="price-line">
                    <div className="title">{item.title}</div>
                    <div className="dot" />
                    <div className="price">{item.price}€</div>
                  </div>
                  <div className="description">{item.description}</div>
                </div>
              );
            })}
        </Card.Content>
      </Card>
    </>
  );
};

export default View;
