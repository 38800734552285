import React from 'react';
import {
  UniversalLink,
  RenderBlocks,
  ConditionalLink,
} from '@plone/volto/components';
import { Segment, Header, List, Container } from 'semantic-ui-react';
import { CarouselImage } from '..';
const LocationView = (props) => {
  const { content } = props;

  return (
    <>
      <Container>
        <RenderBlocks {...props}>
          <Segment>
            <Header>Info pratique</Header>
            <List horizontal relaxed verticalAlign="top">
              {content.price && (
                <List.Item id="price">
                  <List.Header>Prix d'entré</List.Header>
                  <List.Content>{content.price} €</List.Content>
                </List.Item>
              )}
              {content.latitude && content.longitude && (
                <List.Item id="coordinate">
                  <List.Header>Coordonnées</List.Header>
                  <List.Content>
                    <ConditionalLink
                      to={content.google_url && content.google_url}
                      condition={content.google_url}
                    >
                      {content.latitude}, {content.longitude}
                    </ConditionalLink>
                  </List.Content>
                </List.Item>
              )}
              {content.adress && content.city && content.zip_code && (
                <List.Item id="adress">
                  <List.Header>Adresse</List.Header>
                  <List.Content>
                    <ConditionalLink
                      to={content.google_url && content.google_url}
                      condition={content.google_url}
                    >
                      {content.adress}, {content.zip_code} {content.city}
                      <div class="country">
                        {content.country && content.country.title}
                      </div>
                    </ConditionalLink>
                  </List.Content>
                </List.Item>
              )}
              {content.phone && (
                <List.Item id="phone">
                  <List.Header>Téléphone</List.Header>
                  <List.Content>
                    <UniversalLink href={`tel:${content.phone}`}>
                      {content.phone}
                    </UniversalLink>
                  </List.Content>
                </List.Item>
              )}
              {content.site_url && (
                <List.Item id="url">
                  <List.Header>Site Web</List.Header>
                  <List.Content>
                    <UniversalLink href={content.site_url}>
                      {content.site_url}
                    </UniversalLink>
                  </List.Content>
                </List.Item>
              )}
              {content.mail && (
                <List.Item id="mail">
                  <List.Header>Email</List.Header>
                  <List.Content>
                    <UniversalLink href={`mailto:${content.mail}`}>
                      {content.mail}
                    </UniversalLink>
                  </List.Content>
                </List.Item>
              )}
            </List>
          </Segment>
        </RenderBlocks>
        <CarouselImage content={content} />
      </Container>
    </>
  );
};

export default LocationView;
