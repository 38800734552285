import React from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@plone/volto/components';
import { addUserMember } from '@root/actions';

const CreateNewUserForm = (props) => {
  const dispatch = useDispatch();

  const schema = {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'firstName',
          'lastName',
          'email',
          'groups',
          'usernameOverride',
        ],
      },
    ],
    properties: {
      firstName: {
        title: 'First Name',
      },
      lastName: {
        title: 'Last Name',
      },
      email: {
        title: 'Email',
        widget: 'email',
      },
      groups: {
        title: 'Groups',
        type: 'array',
        choices: [
          ['club_members', 'Club Members'],
          ['comity_member', 'Comity Members'],
          ['club_guests', 'Club Guests'],
        ],
        default: 'club_members',
      },
      usernameOverride: {
        title: 'Username Override',
        description:
          "Usualy the username is form with the first letter of the first name and whole last name (eg: Julien Chandelle -> jchandelle), it's possible two different personne give the same username (eg: Jean Chandelle -> jchandelle), with this fieldy you can override the username (eg: Jean Chandelle -> jechandelle)",
      },
    },
    required: ['firstName', 'lastName', 'email'],
  };
  const onSubmit = (value) => {
    dispatch(addUserMember(value)).then((res) => props.getData(res));
    props.onSubmit();
  };

  return (
    <>
      <Form
        schema={schema}
        onSubmit={onSubmit}
        onCancel={() => props.onSubmit()}
      />
    </>
  );
};

export default CreateNewUserForm;
