export const Schema = (props) => {
  return {
    title: 'Button',

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'tooltip', 'url', 'internLink', 'target'],
      },
    ],

    properties: {
      title: {
        title: 'Title',
      },
      tooltip: {
        title: 'Tooltip',
      },
      url: {
        title: 'Link',
        widet: 'url',
      },
      internLink: {
        title: 'Intern Link',
        widget: 'object_browser',
        return: 'single',
      },
      target: {
        title: 'Target',
        description:
          'Comment va réagir la fenetre apres a voir cliqué sur le lien',
        choices: [
          ['_self', 'Ouvrir le lien dans la même page'],
          [
            '_blank',
            'Ouvrir le lien dans un nouvel onglet ou nouvelle fenetre',
          ],
        ],
        default: '_self',
      },
    },
    required: [],
  };
};
