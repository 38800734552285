import { READ_ADMIN_PAGE, UPDATE_MEMBERS } from '~/constants/ActionTypes';

const initialState = {
  read: {
    loaded: false,
    loading: false,
    error: null,
  },
  update: {
    loaded: false,
    loading: false,
    error: null,
  },
  permission: null,
  members: null,
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * footer reducer.
 * @function footer
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function adminPage(state = initialState, action = {}) {
  let { result } = action;
  switch (action.type) {
    case `${UPDATE_MEMBERS}_PENDING`:
    case `${READ_ADMIN_PAGE}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${READ_ADMIN_PAGE}_SUCCESS`:
      return {
        ...state,
        permission: result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${UPDATE_MEMBERS}_SUCCESS`:
      return {
        ...state,
        members: result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${UPDATE_MEMBERS}_FAIL`:
    case `${READ_ADMIN_PAGE}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
