import React from 'react';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const divingCertificateWidget = (props) => {
  const { value } = props;

  function onChange(id, value) {
    props.onChange(id, { items: value });
  }

  function schema(props) {
    return {
      title: 'Diving Certificate',
      addMessage: 'Add a certificate',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['level', 'date'],
        },
      ],

      properties: {
        level: {
          title: 'Certificate Level',
          choices: [
            ['NB', 'Non Breveté'],
            ['1', '1*'],
            ['2', '2*'],
            ['3', '3*'],
            ['4', '4*'],
            ['AM', 'AM'],
            ['MC', 'MC'],
            ['MF', 'MF'],
            ['MN', 'MN'],
          ],
        },
        date: {
          title: 'Date',
          widget: 'date',
        },
      },
      required: ['level'],
    };
  }

  return (
    <ObjectListWidget
      {...props}
      value={value?.items}
      schema={schema}
      onChange={onChange}
    />
  );
};

export default divingCertificateWidget;
