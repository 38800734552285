import { ADD_USER_MEMBER } from '~/constants/ActionTypes';

export function addUserMember(data) {
  return {
    type: ADD_USER_MEMBER,
    request: {
      op: 'post',
      path: `/@add-user`,
      data,
    },
  };
}
