import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { flattenToAppURL } from '@plone/volto/helpers';
// carousel styles
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const CarouselImage = ({ content }) => {
  const { items } = content;
  if (items.length <= 0) return <></>;

  return (
    <Carousel
      emulateTouch
      infiniteLoop
      showStatus={false}
      showThumbs={false}
      showIndicators={content.items.length > 1}
      dynamicHeight={true}
    >
      {content.items.map((image, index) => (
        <img
          key={index}
          alt={image.title}
          src={flattenToAppURL(image.url) + '/@@images/image/preview'}
        />
      ))}
    </Carousel>
  );
};

export default CarouselImage;
