import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

export default forwardRef((props, ref) => {
  const certifMapping = props.colDef.certifMapping;

  const [certifLevel, setCertifLevel] = useState(
    Object.assign(
      {},
      ...Object.keys(certifMapping).map((x) => ({ [x]: false })),
    ),
  );

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return Object.values(certifLevel).some((elem) => elem);
      },
      doesFilterPass(params) {
        const certif = params.data[props.colDef.field];
        return Object.keys(certifLevel).some(
          (elem) => certifLevel[elem] && certif === elem,
        );
      },
      // this example isn't using getModel() and setModel(),
      // so safe to just leave these empty. don't do this in your code!!!
      getModel() {},

      setModel() {},
    };
  });

  const onFilterChange = (event, data) => {
    setCertifLevel((prev) => {
      return { ...prev, [data.value]: data.checked };
    });
  };

  useEffect(() => {
    props.filterChangedCallback();
  }, [certifLevel]);

  return (
    <div>
      <div className="filter-title">Certif Filter</div>
      <Form>
        {Object.keys(certifMapping).map((key, index) => {
          const values = certifMapping[key];
          return (
            <Form.Field key={key}>
              <Checkbox
                label={values.title}
                value={key}
                onChange={onFilterChange}
              />
            </Form.Field>
          );
        })}
      </Form>
    </div>
  );
});
