import React from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@plone/volto/components';
import { updateUserData } from '~/actions';

const UserFormView = (props) => {
  const { onFormSubmit, formData, formType } = props;
  const dispatch = useDispatch();
  const Userform = (formType) => {
    const fieldsets = [
      {
        id: 'personal',
        title: 'Donnée personnelles',
        fields: [
          'first_name',
          'last_name',
          'adress',
          'zip_code',
          'city',
          'dob',
          'number_under_roof',
          'job',
          'phone',
          'email',
          'image',
        ],
      },
    ];

    if (formType === 'register') {
      fieldsets.unshift({
        id: 'diving',
        title: 'Donnée sur la plongée',
        columns: 2,
        fields: [
          'second_club',
          'first_club_name',
          'diving_certificate',
          'diving_certificate_number',
          'nitrox_certificate',
          'nitrox_certificate_number',
          'security_certificate',
          'last_secu_certificate_date',
          'other_certificate',
          'last_other_certificate_date',
          'last_medical_visit_date',
          'last_ecg_date',
        ],
      });
    }
    return {
      fieldsets: fieldsets,
      properties: {
        first_name: { type: 'string', title: 'Prénom' },
        last_name: { type: 'string', title: 'Nom de famille' },
        adress: { type: 'string', title: 'Adresse' },
        zip_code: { type: 'string', title: 'Code postal' },
        city: { type: 'string', title: 'Localité' },
        dob: {
          widget: 'date',
          title: 'Date de naissance',
          description:
            "Il est nécessaire d'avoir atteint l'âge de 14 ans à la date de l'inscription",
        },
        job: { type: 'string', title: 'Profession' },
        phone: { type: 'string', title: 'Téléphone' },
        email: { type: 'email', title: 'Email' },
        number_under_roof: {
          title: 'Quantieme sous le même toit',
          choices: [
            ['1', 'First one'],
            ['2', 'Second under the roof'],
            ['3', 'Third under the roof or more'],
          ],
        },
        image: {
          widget: 'file',
          title: 'Photo',
        },
        second_club: {
          title: '2ème appartenance',
          choices: [
            ['y', 'Oui'],
            ['n', 'Non'],
          ],
        },
        first_club_name: {
          type: 'string',
          title: 'si oui : Nom du 1er club',
        },
        diving_certificate: {
          title: 'Brevet de plongée',
          choices: [
            ['NB', 'Non Breveté'],
            ['1', '1*'],
            ['2', '2*'],
            ['3', '3*'],
            ['4', '4*'],
            ['AM', 'AM'],
            ['MC', 'MC'],
            ['MF', 'MF'],
            ['MN', 'MN'],
          ],
          default: 'NB',
          noValueOption: false,
        },
        diving_certificate_number: {
          type: 'string',
          title: 'Numéro du brevet de plongée',
        },
        nitrox_certificate: {
          title: 'Brevet de nitrox',
          choices: [
            ['plongeur', 'Plongeur'],
            ['pl_confirmed', 'Plongeur Confirmé'],
            ['instructeur', 'Instructeur'],
            ['instructeur_confirmed', 'Instructeur Confirmé'],
          ],
        },
        nitrox_certificate_number: {
          type: 'string',
          title: 'Numéro du brevet de nitrox',
        },
        security_certificate: {
          title: 'Brevet de sécurité',
          description:
            'Secouriste plongeur, Certificat Fédéral Premiers Secours ou autre',
        },
        last_secu_certificate_date: {
          widget: 'date',
          title: 'Date de dernier brevet de sécurité',
        },
        other_certificate: {
          title: 'Autre brevet de secourisme',
          description: '(DAN, ...)',
        },
        last_other_certificate_date: {
          widget: 'date',
          title: 'Date de dernier brevet de secourisme',
        },
        last_medical_visit_date: {
          widget: 'date',
          title: 'Date de dernière visite médicale',
        },
        last_ecg_date: {
          widget: 'date',
          title: 'Date de dernière ECG sous effort',
        },
      },
      required: [
        'first_name',
        'last_name',
        'adress',
        'zip_code',
        'city',
        'dob',
        'phone',
        'email',
      ],
    };
  };

  const onSubmit = (value) => {
    dispatch(updateUserData(value, formType));
    onFormSubmit();
  };
  return (
    <Form
      schema={Userform(formType, formType)}
      submitLabel="Inscrivez-vous au CPSM"
      onSubmit={onSubmit}
      resetAfterSubmit={false}
      formData={formData}
    />
  );
};

export default UserFormView;
