import React from 'react';
import { Card } from 'semantic-ui-react';
import { FormattedDate } from '@plone/volto/components';
import cx from 'classnames';

const BaseWidget = (props) => {
  const {
    titleFn,
    metaFn = null,
    items,
    className = null,
    firstHighlight = false,
  } = props;
  return (
    <Card.Group className={cx('certif-widget', { [className]: className })}>
      {items.map((item, i) => {
        return (
          <Card
            className={cx({
              highlight: firstHighlight && i === 0,
              'no-light': firstHighlight && i > 0,
            })}
          >
            <Card.Content>
              <Card.Header>{titleFn(item)}</Card.Header>
              {metaFn && <Card.Meta>{metaFn(item)}</Card.Meta>}
              <Card.Description>
                {item.date && <FormattedDate date={item.date} locale="fr" />}
              </Card.Description>
            </Card.Content>
          </Card>
        );
      })}
    </Card.Group>
  );
};

export default BaseWidget;
