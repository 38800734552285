import React from 'react';
import { Modal } from 'semantic-ui-react';
import { UserFormView, Payment } from '~/components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { readUserData } from '@root/actions';

const ModalFrom = (props) => {
  const token = useSelector((state) => state?.userSession?.token, shallowEqual);

  const [openModal, setOpenModal] = React.useState(false);
  const [openPayment, setOpenPayment] = React.useState(false);
  const [openUserFormView, setOpenUserFormView] = React.useState(true);
  const [formType, setFormType] = React.useState();
  const userData = useSelector((state) => state.userData?.data);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (token) {
      dispatch(readUserData(false)).then((res) => {
        if (!res.status) return;
        if (res.renew) {
          setFormType('renew');
        }
        if (!res.form_complited) {
          setFormType('register');
          setOpenModal(true);
        }
        if (res.confirm) {
          setFormType('confirm');
          setOpenModal(true);
        }
      });
    }
  }, [dispatch, token]);

  const onFormSubmit = () => {
    setOpenUserFormView(false);
    if (formType === 'register' || formType === 'renew') {
      setOpenPayment(true);
    } else {
      setOpenModal(false);
    }
  };

  const onPaymentClick = () => {
    setOpenModal(false);
  };

  return (
    <Modal open={openModal}>
      <Modal.Header>Formulaire d'inscription</Modal.Header>
      <Modal.Content scrolling>
        {openUserFormView && formType && (
          <UserFormView
            onFormSubmit={onFormSubmit}
            formData={userData?.data && userData.data}
            formType={formType}
          />
        )}
        {openPayment && (formType === 'register' || formType === 'renew') && (
          <Payment onPaymentClick={onPaymentClick} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ModalFrom;
