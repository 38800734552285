import React from 'react';
import BaseWidget from './BaseWidget';

const certifOrder = (a, b) => {
  if (a.date < b.date) {
    return 1;
  }
  if (a.date > b.date) {
    return -1;
  }
  return 0;
};

const otherCertificateWidget = (props) => {
  const { value } = props;
  const certifList = value?.items?.sort(certifOrder);
  return certifList ? (
    <BaseWidget
      className="other-certif"
      titleFn={(item) => `${item.name} - ${item.level}`}
      metaFn={(item) => {
        return item.number && `Certifacte Number: ${item.number}`;
      }}
      items={certifList}
    />
  ) : (
    <></>
  );
};

export default otherCertificateWidget;
