import React from 'react';
import { Card, Divider, Icon } from 'semantic-ui-react';
import { FormattedDate } from '@plone/volto/components';
import { capitilize } from '~/helpers';
var _ = require('lodash');

const AgendaTemplate = (props) => {
  const { items } = props;
  const itemsGroup = _.chain(items)
    .groupBy('start_date')
    .map((value, key) => ({ date: key, items: value }))
    .value();

  return (
    <div className="agenda-template">
      {itemsGroup.map((item, index) => {
        return (
          <div className="day-item" key={index}>
            <h3>
              <FormattedDate
                date={item.date}
                format={{ dateStyle: 'full' }}
                locale="fr"
              >
                {capitilize}
              </FormattedDate>
            </h3>
            <Card.Group>
              {item.items.map((item, index) => {
                return (
                  <Card
                    key={index}
                    href={item.getURL}
                    className={`${item.Type} event-item`}
                  >
                    <Card.Content>
                      <Card.Header>{item.title}</Card.Header>
                      <div className="meta">
                        <Card.Meta>{item.Type}</Card.Meta>
                        <Card.Meta>
                          <FormattedDate
                            date={item.start}
                            format={{ timeStyle: 'short' }}
                            locale="fr"
                          />{' '}
                          -{' '}
                          <FormattedDate
                            date={item.end}
                            format={{ timeStyle: 'short' }}
                            locale="fr"
                          />
                        </Card.Meta>
                      </div>
                      <Divider />
                      <Card.Description>
                        <div className="location">
                          <Icon name="map marker alternate" />
                          {item.location}
                        </div>
                        <div className="organizer">
                          {item.organizer.map((item, index) => {
                            return (
                              <div key={index}>
                                <Icon name="user" /> <span>{item}</span>
                              </div>
                            );
                          })}
                        </div>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                );
              })}
            </Card.Group>
          </div>
        );
      })}
    </div>
  );
};

export default AgendaTemplate;
