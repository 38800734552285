import React from 'react';
import { Card } from 'semantic-ui-react';
import { Component } from '@plone/volto/components';
import { DivingLevel } from '@root/components';

const MemberTemplate = (props) => {
  const { items } = props;

  return (
    <div className="member-template">
      {items.map((item, index) => {
        return (
          <Card key={item['@id']} className="item">
            <Component
              componentName="PreviewImage"
              item={item}
              alt={`Image de ${item.first_name} ${item.last_name}`}
            />
            <Card.Content>
              <Card.Header>{`${item.first_name} ${item.last_name}`}</Card.Header>
              <Card.Meta>
                <DivingLevel level={item.diving_level} />
              </Card.Meta>
            </Card.Content>
          </Card>
        );
      })}
    </div>
  );
};

export default MemberTemplate;
