import React from 'react';
import { Card } from 'semantic-ui-react';
import { Component } from '@plone/volto/components';

const LocationTemplate = (props) => {
  const { items } = props;

  return (
    <div className="location-template">
      {items.map((item, index) => {
        return (
          <Card key={index} as="a" href={item['@id']} className="item">
            <Component
              componentName="PreviewImage"
              item={item}
              alt={item.title}
            />
            <Card.Header>{item.title}</Card.Header>
          </Card>
        );
      })}
    </div>
  );
};

export default LocationTemplate;
