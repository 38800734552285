import React, { Component } from 'react';
import { SidebarPortal } from '@plone/volto/components';
import Body from './Body';
import Data from './Data';

export default class Edit extends Component {
  render() {
    return (
      <>
        <Body {...this.props} id={this.props.id} isEditMode />
        <SidebarPortal selected={this.props.selected}>
          <Data
            key={this.props.block}
            {...this.props}
            data={this.props.data}
            block={this.props.block}
            onChangeBlock={this.props.onChangeBlock}
          />
        </SidebarPortal>
      </>
    );
  }
}
