import React, { useEffect, useState } from 'react';
import { Form } from '@plone/volto/components';
import { useDispatch } from 'react-redux';
import { csvSendData } from '@root/actions';
import CheckDiffView from './CheckDiffView';

const CheckCSV = (props) => {
  const schema = {
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['csv'],
      },
    ],
    properties: {
      csv: {
        title: 'CSV',
        widget: 'file',
      },
    },
    required: ['csv'],
  };
  const dispatch = useDispatch();

  const [showResult, setShowResult] = useState(false);

  const onSubmit = (data) => {
    dispatch(csvSendData(data, 'check-data')).then(() => setShowResult(true));
  };

  return (
    <>
      {!showResult && (
        <Form
          schema={schema}
          onSubmit={onSubmit}
          resetAfterSubmit={false}
          onCancel={props.onClose}
        />
      )}
      {showResult && <CheckDiffView />}
    </>
  );
};

export default CheckCSV;
