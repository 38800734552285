/**
 * Routes.
 * @module routes
 */

import { App } from '@plone/volto/components';
import { defaultRoutes } from '@plone/volto/routes';
import config from '@plone/volto/registry';
import { UserFormView, ClubAdminView, ImportDataView } from './components';
/**
 * Routes array.
 * @array
 * @returns {array} Routes.
 */
const routes = [
  {
    path: '/',
    component: App, // Change this if you want a different component
    routes: [
      {
        path: '/user-edit-form',
        component: UserFormView,
      },
      {
        path: '/admin-club',
        component: ClubAdminView,
      },
      {
        path: '/import-data',
        component: ImportDataView,
      },
      // Add your routes here
      ...(config.addonRoutes || []),
      ...defaultRoutes,
    ],
  },
];

export default routes;
