/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import affiniticvolto from '@affinitic/volto';
import eeacmsvoltoOpenlayersMap from '@eeacms/volto-openlayers-map';
import kitconceptvoltoBlocksGrid from '@kitconcept/volto-blocks-grid';
import voltoGoogleAnalytics from 'volto-google-analytics';
import eeacmsvoltoResizeHelper from '@eeacms/volto-resize-helper';
import eeacmsvoltoBlockStyle from '@eeacms/volto-block-style';

const addonsInfo = [{"modulePath":"/volto/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/volto/node_modules/@plone/volto/packages/volto-slate/package.json","version":"16.7.0","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"name":"@affinitic/volto","version":"1.4.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/volto/node_modules/@affinitic/volto/src","packageJson":"/volto/node_modules/@affinitic/volto/package.json","addons":[]},{"name":"@eeacms/volto-openlayers-map","version":"0.2.1","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/volto/node_modules/@eeacms/volto-openlayers-map/src","packageJson":"/volto/node_modules/@eeacms/volto-openlayers-map/package.json","addons":[]},{"name":"@kitconcept/volto-blocks-grid","version":"5.2.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/volto/node_modules/@kitconcept/volto-blocks-grid/src","packageJson":"/volto/node_modules/@kitconcept/volto-blocks-grid/package.json","addons":[]},{"name":"volto-google-analytics","version":"2.0.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/volto/node_modules/volto-google-analytics/src","packageJson":"/volto/node_modules/volto-google-analytics/package.json","addons":[]},{"name":"@eeacms/volto-resize-helper","version":"1.0.1","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/volto/node_modules/@eeacms/volto-resize-helper/src","packageJson":"/volto/node_modules/@eeacms/volto-resize-helper/package.json","addons":[]},{"name":"@eeacms/volto-block-style","version":"4.1.2","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/volto/node_modules/@eeacms/volto-block-style/src","packageJson":"/volto/node_modules/@eeacms/volto-block-style/package.json","addons":["@eeacms/volto-resize-helper"]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, affiniticvolto, eeacmsvoltoOpenlayersMap, kitconceptvoltoBlocksGrid, voltoGoogleAnalytics, eeacmsvoltoResizeHelper, eeacmsvoltoBlockStyle];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
