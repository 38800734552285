import React from 'react';
import { Button, Popup } from 'semantic-ui-react';

const View = (props) => {
  const { data } = props;

  return (
    <>
      <Popup
        trigger={
          <Button
            as="a"
            target={data.target}
            href={
              data.internLink[0]
                ? data.internLink?.[0]?.['@id']
                : data.url
                ? data.url
                : ''
            }
          >
            {data.title}
          </Button>
        }
        disable={!data.tooltip}
      >
        {data.tooltip}
      </Popup>
    </>
  );
};

export default View;
