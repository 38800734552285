import React, { useRef } from 'react';
import cx from 'classnames';

import './RadioInput.less';

const RadioInput = (props) => {
  const {
    children,
    memberId,
    memberName,
    field,
    side,
    value,
    onChange,
    checked = false,
  } = props;

  const ref = useRef(null);

  const onChangeInput = (evt) => {
    onChange && onChange(value, memberName, memberId, field, side);
  };

  const inputName = `${memberId}-${field.cpsm}`;
  const inputId = `${inputName}-${side}`;

  return (
    <div
      className={cx('radio-input', {
        checked: ref?.current?.checked,
      })}
    >
      <input
        ref={ref}
        type="radio"
        name={inputName}
        id={inputId}
        onChange={onChangeInput}
      />
      <label for={inputId}>{children}</label>
    </div>
  );
};

export default RadioInput;
