import React from 'react';
import { DefaultView } from '@plone/volto/components';
import { Button, Container, Icon } from 'semantic-ui-react';
import { flattenToAppURL, Helmet } from '@plone/volto/helpers';

const MemberView = (props) => {
  const { content } = props;

  return (
    <>
      <Helmet title={content.first_name + ' ' + content.last_name} />
      <Container>
        <div className="toolsbar">
          <Button as="a" href={flattenToAppURL('/admin-club')}>
            <Icon name="arrow alternate circle left outline" />
            Retour a l'administration du club
          </Button>
        </div>
      </Container>
      <DefaultView {...props} />
    </>
  );
};

export default MemberView;
