/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */
import {
  LocationView,
  EventView,
  PriceListView,
  PriceListEdit,
  ButtonView,
  ButtonEdit,
  LocationTemplate,
  AgendaTemplate,
  MemberTemplate,
  divingCertificateWidget,
  nitroxCertificateWidget,
  securityCertificateWidget,
  otherCertificateWidget,
  divingCertificateViewWidget,
  nitroxCertificateViewWidget,
  securityCertificateViewWidget,
  otherCertificateViewWidget,
  MemberView,
} from './components';

import { DefaultView } from '@plone/volto/components';

import listSVG from '@plone/volto/icons/content-listing.svg';
import linkSVG from '@plone/volto/icons/link.svg';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

const applyConfig = (config) => {
  // Add here your project's configuration here by modifying `config` accordingly
  config.settings.navDepth = 3;
  config.settings.defaultLanguage = 'fr';

  config.views.contentTypesViews.ClubEvent = EventView;
  config.views.contentTypesViews.Location = LocationView;
  config.views.contentTypesViews.Member = MemberView;

  config.views.contentTypesViews.MembersPage = DefaultView;
  config.views.contentTypesViews.LocationsPage = DefaultView;
  config.views.contentTypesViews.Agenda = DefaultView;

  config.widgets.widget.divingCertificateWidget = divingCertificateWidget;
  config.widgets.widget.nitroxCertificateWidget = nitroxCertificateWidget;
  config.widgets.widget.securityCertificateWidget = securityCertificateWidget;
  config.widgets.widget.otherCertificateWidget = otherCertificateWidget;

  config.widgets.views.widget.divingCertificateWidget = divingCertificateViewWidget;
  config.widgets.views.widget.nitroxCertificateWidget = nitroxCertificateViewWidget;
  config.widgets.views.widget.securityCertificateWidget = securityCertificateViewWidget;
  config.widgets.views.widget.otherCertificateWidget = otherCertificateViewWidget;

  config.settings.nonContentRoutes.push('/admin-club');
  config.settings.nonContentRoutes.push('/import-data');

  config.experimental.addBlockButton.enabled = true;

  config.blocks.requiredBlocks = [];

  config.blocks.blocksConfig['PriceList'] = {
    id: 'PriceList',
    title: 'Price List',
    icon: listSVG,
    group: 'common',
    view: PriceListView,
    edit: PriceListEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig['Button'] = {
    id: 'Button',
    title: 'Button',
    icon: linkSVG,
    group: 'common',
    view: ButtonView,
    edit: ButtonEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  config.blocks.blocksConfig.listing['variations'] = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'LocationTemplate',
      title: 'Location View',
      template: LocationTemplate,
    },
    {
      id: 'AgendaTemplate',
      title: 'Agenda View',
      template: AgendaTemplate,
    },
    {
      id: 'MemberTemplate',
      title: 'Member View',
      template: MemberTemplate,
    },
  ];

  return config;
};

export default applyConfig;
